import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { expandTheme } from 'utils/theme-util';

export const useStyles = makeStyles((theme: Theme) => {
  const { palette } = expandTheme(theme);

  return {
    badge: {
      backgroundColor: palette.primary.dark,
      color: palette.primary.white,
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0.2),
      marginTop: theme.spacing(-0.2),
      marginLeft: theme.spacing(0.5),
      fontSize: '11px',
      fontWeight: 700,
    },
    tooltip: {
      display: 'flex',
      cursor: 'pointer',
    },
  };
});
