import React from 'react';
import { Tooltip } from '@mui/material';
import { INinetyDaysBadgeProps } from './ninety-days-badge.interface';
import { useStyles } from './ninety-days-badge.styles';

const NinetyDaysBadge = ({ daysSupplyLabel }: INinetyDaysBadgeProps): JSX.Element => {
  const useStyle = useStyles();

  return (
    <Tooltip
      className={useStyle.tooltip}
      title="Investigate 90 day fill for this therapy"
      placement="top-start"
    >
      <div style={{ display: 'flex' }}>
        <span style={{ display: 'flex' }}>{daysSupplyLabel}</span>
        <div className={useStyle.badge}>90</div>
      </div>
    </Tooltip>
  );
};

export default NinetyDaysBadge;
