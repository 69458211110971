import React, { useState } from 'react';
import { Grid, Modal, Tooltip, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { nameOfFactory } from 'utils/types-util';
import { WithStyles } from '@mui/styles';
import { addEmailModalActions } from 'actions/action-add-email-modal';
import { addPaymentMethodModalActions } from 'actions/action-add-payment-method-modal';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { Email, CheckCircle, CreditCard } from '@mui/icons-material';
import { getModalStyle } from 'services/utils/styles-service';
import { ViewModeEnum } from 'containers/add-payment-method/add-payment-method-modal';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import { PhoneUsage } from 'interfaces/redux/IPhone';
import IconPhoneOptIn from 'components/icons/IconPhoneOptIn';
import ModalWelcomeKit from 'containers/patient/modals/demographics/welcome-kit-modal';
import EditPhonesSmsPreferencesModal, {
  EditModalViewModeEnum,
} from 'components/edit-phones-sms-preferences-modal/edit-phones-sms-preferences-modal';
import { SMSIcon, WKIcon } from '../../../components/icons/icons';
import { IPreferredContact } from '../../../interfaces/redux/IPreferredContact';
import { PatientStyles } from './patient.styles';
import { MobilePhoneSmsEnum } from '../../../constants/enums';
import PaymentMethodStatus from './payment-method-status';

interface IPatientSmsEmail extends IPreferredContact, WithStyles<typeof PatientStyles> {
  wkIsVisible: boolean;
  sourceNumber: string;
  patientLoaded?: boolean;
  expirationDate?: string;
  patientAutoFCStatus?: number;
  autoFCAnchorTherapy?: any;
}

const nameOfFormFields = nameOfFactory<IPatientSmsEmail>();

// eslint-disable-next-line @typescript-eslint/naming-convention
function PatientSmsEmail(props: IPatientSmsEmail): JSX.Element {
  const {
    classes,
    filteredPhones,
    filteredEmails,
    filteredWelcomeKit,
    sourceNumber,
    wkIsVisible = false,
    patientLoaded = true,
    patientAutoFCStatus,
    autoFCAnchorTherapy,
  } = props;

  const [visibleWkPreferencesModal, setVisibleWkPreferencesModal] = useState<boolean>(false);

  const dispatch = useDispatch();
  const anchorTherapy = `${autoFCAnchorTherapy?.drug_name} ${autoFCAnchorTherapy.strength} ${autoFCAnchorTherapy.strength_unit_of_measure} ${autoFCAnchorTherapy.dosage_form_description}`;
  const [visibleEditPhonesSmsPreferencesModal, setEditPhonesSmsPreferencesModal] =
    useState<boolean>(false);

  const showWkPreferencesModal = () => {
    setVisibleWkPreferencesModal(true);
  };

  const showEditPhonesSmsPreferencesModal = () => {
    setEditPhonesSmsPreferencesModal(true);
  };

  const showEmailModal = () => {
    dispatch(
      addEmailModalActions.setState({
        visible: true,
        formId: 'patient-overview-header',
        field: 'patient-overview-email',
        mode: 'therapies',
      }),
    );
  };

  const showPaymentPreferencesModal = () => {
    dispatch(
      addPaymentMethodModalActions.setState({
        visible: true,
        formId: 'patient-overview-header',
        field: 'patient-overview-payment',
        tasks: [],
        viewMode: ViewModeEnum.PatientOverview,
      }),
    );
  };

  const displayPhoneProvided = (): JSX.Element => {
    const classNameIcon =
      filteredPhones &&
      filteredPhones?.length > 0 &&
      filteredPhones.some(
        ({ sms, use }) => sms === MobilePhoneSmsEnum.OptOut && use === PhoneUsage.Mobile,
      )
        ? classes.iconActive
        : classes.iconInactive;
    return <SMSIcon className={classNameIcon} />;
  };

  const displayEmailProvided = (): JSX.Element => {
    const classNameIcon =
      filteredEmails && filteredEmails?.length > 0 ? classes.iconActive : classes.iconInactive;
    return (
      <Email
        className={classNameIcon}
        onClick={() => {
          showEmailModal();
        }}
      />
    );
  };

  const displayEmailOptInCheck = (): JSX.Element => {
    if (!patientLoaded) {
      return <></>;
    }
    const emailSms =
      filteredEmails?.map(email => email.enrollment).filter(enrollment => enrollment != null) ?? [];
    const optIn = emailSms.includes(MobilePhoneSmsEnum.OptIn);
    const optOut = emailSms.includes(MobilePhoneSmsEnum.OptOut);
    const notSpecified = emailSms.includes(MobilePhoneSmsEnum.NotSpecified);
    return (
      <>
        {!emailSms?.length && <InfoIcon className={classes.notProvided} />}
        {optIn && <CheckCircle className={classes.provided} />}
        {optOut && <InfoIcon className={classes.notProvided} />}
        {notSpecified && <InfoIcon className={classes.notProvided} />}
      </>
    );
  };

  const displayWk = (): JSX.Element => {
    const classNameIcon = filteredWelcomeKit ? classes.iconActive : classes.iconInactive;
    const wkIcon = (
      <WKIcon
        onClick={() => {
          showWkPreferencesModal();
        }}
        className={classNameIcon}
      />
    );
    return (
      <>
        {filteredWelcomeKit ? (
          <Tooltip title="Welcome Kit/Patient Educational materials received" placement="bottom">
            <span>{wkIcon}</span>
          </Tooltip>
        ) : (
          wkIcon
        )}
      </>
    );
  };

  const displayWkCheck = (): JSX.Element => {
    return filteredWelcomeKit ? (
      <CheckCircle className={classes.provided} />
    ) : (
      <CancelIcon className={classes.notProvided} />
    );
  };

  return (
    <>
      {/* Modals */}
      {/* @ts-ignore */}
      <ModalWelcomeKit
        // @ts-ignore
        visible={visibleWkPreferencesModal}
        // @ts-ignore
        onModalClose={() => {
          setVisibleWkPreferencesModal(false);
        }}
      />
      {/* @ts-ignore */}
      <EditPhonesSmsPreferencesModal
        visible={visibleEditPhonesSmsPreferencesModal}
        videMode={EditModalViewModeEnum.EditPhoneAndSmsPreferences}
        onModalClose={() => {
          setEditPhonesSmsPreferencesModal(false);
        }}
      />
      {/* /Modals */}
      <Grid container alignItems="center">
        <Typography>Ttes</Typography>
        <Grid
          item
          onClick={() => {
            showEditPhonesSmsPreferencesModal();
          }}
        >
          {displayPhoneProvided()}
          <IconPhoneOptIn
            phones={filteredPhones || []}
            sourceNumber={sourceNumber}
            patientAutoFCStatus={patientAutoFCStatus || null}
            anchorTherapy={anchorTherapy}
          />
        </Grid>
        <Grid item>|</Grid>
        <Grid item>
          {displayEmailProvided()}
          {displayEmailOptInCheck()}
        </Grid>
        {true && (
          <>
            <Grid item>|</Grid>
            <Grid item>
              {displayWk()}
              {displayWkCheck()}
            </Grid>
          </>
        )}
        <Grid item>|</Grid>
        <Grid
          item
          onClick={() => {
            showPaymentPreferencesModal();
          }}
        >
          <CreditCard
            className={props.expirationDate ? classes.iconActive : classes.iconInactive}
          />
          <PaymentMethodStatus expiration={props.expirationDate} />
        </Grid>
      </Grid>
      <br />
    </>
  );
}

export default withStyles(PatientStyles)(PatientSmsEmail);
